import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <h3>{t("Company - AboutUs - Title - 1")}</h3>
              <p>{t("Company - AboutUs - Text - 1")}</p>
              <p>{t("Company - AboutUs - Text - 2")}</p>
              <p>{t("Company - AboutUs - Text - 3")}</p>
              <p>{t("Company - AboutUs - Text - 4")}</p>
              <p>{t("Company - AboutUs - Text - 5")}</p>
              <p>{t("Company - AboutUs - Text - 6")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
